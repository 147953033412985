<template>
  <v-container fluid class="pa-0">
    <shop-header :title="title" :description="description"></shop-header>
    <v-divider></v-divider>
    <v-breadcrumbs v-if="group && content" :items="breadcrumbs"></v-breadcrumbs>

    <v-card flat>
      <v-card-title primary-title class="title pb-2">{{
        item.shop_item_title
      }}</v-card-title>
      <v-card-text>
        {{ item.shop_item_description }}
      </v-card-text>
      <v-card-text class="pt-0">
        <v-img
          contain
          max-width="500px"
          :src="$config.managerMedia + 'picturepool/' + item.shop_item_image"
          :lazy-src="
            $config.managerMedia + 'picturepool/' + item.shop_item_image
          "
        ></v-img>
      </v-card-text>
      <v-card flat>
        <v-card-title>{{
          item.shop_item_price | currency($store.state.session.currencyConfig)
        }}</v-card-title>
        <v-card-text>
          <v-select
            hide-details
            outlined
            :label="$t('modal.quantitySelection.title')"
            item-text="quantity"
            v-model="quantity"
            :items="quantitys"
          ></v-select>
        </v-card-text>
      </v-card>
      <v-card-text class="pt-0">
        <v-btn color="primary" block @click="addItem()">{{
          $t("shop.purchaseFlowAddToCart")
        }}</v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="flowDialog" persistent max-width="400" scrollable>
      <v-card flat>
        <v-card-text class="pa-0">
          <v-stepper v-model="step">
            <v-stepper-header>
              <template v-for="n in steps">
                <v-stepper-step :key="'step' + n" :complete="step > n" :step="n"
                  >Step {{ n }}</v-stepper-step
                >
                <v-divider v-if="n !== steps" :key="n"></v-divider>
              </template>
            </v-stepper-header>
            <v-card-title>{{ $t("shop.selectInserts") }}</v-card-title>
            <v-stepper-items>
              <v-stepper-content
                v-for="n in steps"
                :key="'content-' + n"
                :step="n"
                class="pa-0"
              >
                <v-card-text class="pb-0 pt-0">
                  <v-alert
                    v-model="maxSelects"
                    :type="type"
                    class="text-caption"
                    >{{
                      $t(
                        type === "info"
                          ? "shop.selectMaxInserts"
                          : "shop.shopMaxSelectsOverhead",
                        { max_selects: item.purchase_flow[n - 1].max_selects }
                      )
                    }}</v-alert
                  >
                </v-card-text>
                <v-list two-line>
                  <template
                    v-for="(subItem, index) in item.purchase_flow[n - 1]
                      .sub_items"
                  >
                    <v-divider
                      :key="'divider-' + index"
                      v-if="index > 0"
                    ></v-divider>
                    <v-list-item
                      @click.capture.stop="subItem.selected = !subItem.selected"
                      :key="index"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          v-model="subItem.selected"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="subItem.sub_item_title"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            subItem.sub_item_price
                              | currency($store.state.session.currencyConfig)
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-img
                          max-width="80px"
                          max-height="80px"
                          :key="'image' + index"
                          :src="
                            $config.managerMedia +
                            'picturepool/' +
                            subItem.sub_item_image
                          "
                          :lazy-src="
                            $config.managerMedia +
                            'picturepool/' +
                            subItem.sub_item_image
                          "
                        ></v-img>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="previousStep(step)">{{
            $t("shop.purchaseFlowBack")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="continueDisabled"
            @click="nextStep(step)"
            >{{ $t("shop.purchaseFlowContinue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import filter from "lodash/filter";
import each from "lodash/each";
import ShopHeader from "@/components/shop/ShopHeader";

export default {
  components: {
    ShopHeader,
  },
  data() {
    return {
      group: undefined,
      quantity: 1,
      flowDialog: false,
      step: 1,
      maxSelects: true,
      type: "info",
    };
  },
  methods: {
    ...mapActions("shop", [
      "getInfo",
      "getContent",
      "setPurchaseFlowCache",
      "modifyPurchaseFlowSubitemSelected",
      "addToCartList",
      "addPurchaseFlowItemToCart",
    ]),
    initData() {
      this.getInfo(this.$route.params.id);
      this.getContent(this.$route.params.id);
    },
    nextStep(n) {
      if (
        filter(this.item.purchase_flow[n - 1].sub_items, { selected: true })
          .length > this.item.purchase_flow[n - 1].max_selects
      ) {
        this.type = "error";
        return;
      } else {
        this.type = "info";
        //always iterate through all items in case user went one step back and changed selection
        each(this.item.purchase_flow[n - 1].sub_items, (subItem) => {
          this.modifyPurchaseFlowSubitemSelected({
            subItemId: subItem.sub_item_id,
            isSelected: subItem.selected,
          });
        });
        if (n === this.steps) {
          this.step = 1;
          this.flowDialog = false;
          this.addPurchaseFlowItemToCart();
        } else {
          this.step = n + 1;
        }
      }
    },
    previousStep(n) {
      if (n === 1) {
        this.flowDialog = false;
      } else {
        this.step = n - 1;
      }
    },
    addItem() {
      if (this.item.purchase_flow && this.item.purchase_flow.length > 0) {
        this.flowDialog = true;
        this.setPurchaseFlowCache({
          itemId: this.item.id,
          quantity: parseInt(this.quantity),
          copy: true,
        });
      } else {
        this.addToCartList({
          itemId: this.item.id,
          quantity: parseInt(this.quantity),
        });
      }
      /* Save shops that have items in the cart
      to be able to print shop names in ShopCart.vue */
      var data = { id: this.$route.params.id, title: this.title };
      if (this.shops.filter((o) => o.id === data.id).length === 0) {
        this.$store.commit("shop/ADD_TO_SHOPS", data);
      }
    },
    getAllGroups() {
      return this.getGroupsFromItems({
        items: this.content.retail_items,
      });
    },
    getGroupsFromItems({ items }) {
      let groups = [];
      items.forEach((element) => {
        if (element.type === "group") {
          groups.push(element);
          if (element.retail_items && element.retail_items.length) {
            groups = [
              ...groups,
              ...this.getGroupsFromItems({
                items: element.retail_items,
              }),
            ];
          }
        }
      });

      return groups;
    },
  },
  computed: {
    ...mapState("shop", {
      statusContent: (state) => state.statusContent,
      content: (state) => state.content,
      activeGroup: (state) => state.activeGroup,
      parentGroup: (state) => state.parentGroup,
      rootTemplateData: (state) => state.rootTemplateData,
      info: (state) => state.info,
      title: (state) => state.title,
      description: (state) => state.description,
      shops: (state) => state.shops,
    }),
    groups() {
      return this.getAllGroups();
    },
    currentGroup() {
      let groups = this.$route.query.groups;
      if (!groups) {
        return this.content;
      }

      if (typeof groups !== "object") {
        groups = [groups];
      }

      return this.groups.filter((element) => {
        return element.id === groups[groups.length - 1];
      })[0];
    },
    items() {
      return this.currentGroup.retail_items;
    },
    item() {
      if (this.items) {
        const item = filter(this.items, { id: this.$route.params.productId });
        if (item) {
          return item[0];
        }
      }
    },
    breadcrumbs() {
      let { groups = [] } = this.$route.query;

      if (typeof groups !== "object") {
        groups = [groups];
      }

      const items = [
        {
          exact: true,
          text: this.title,
          to: {
            name: "shop",
            params: { id: this.$route.params.id },
            query: { ...this.$route.query, groups: undefined },
          },
        },
      ];

      const breadcrumbGroups = [];
      groups.forEach((id) => {
        const item = this.groups.filter((item) => {
          return item.id === id;
        })[0];
        breadcrumbGroups.push(item.id);
        items.push({
          exact: true,
          to: {
            name: "shop",
            params: { id: this.$route.params.id },
            query: { ...this.$route.query, groups: [...breadcrumbGroups] },
          },
          text: item.shop_item_title,
        });
      });

      items.push({
        to: {
          name: "shopProductDetail",
          params: this.$route.params,
          query: this.$route.query,
        },
        text: this.item.shop_item_title,
        exact: true,
      });

      return items;
    },
    quantitys() {
      var q = [];
      for (var i = 1; i < 101; i++) {
        q.push({ quantity: i });
      }
      return q;
    },
    steps() {
      return this.item.purchase_flow.length;
    },
    continueDisabled() {
      if (this.item.purchase_flow.length > 0) {
        return (
          filter(this.item.purchase_flow[this.step - 1].sub_items, {
            selected: true,
          }).length === 0
        );
      } else {
        return false;
      }
    },
  },
  created() {
    this.initData();
  },
  watch: {
    "$route.params.id"() {
      this.initData();
    },
    groups() {
      this.group = filter(this.groups, { id: this.$route.params.groupId });
    },
  },
};
</script>
